window._ = require('lodash');

try
{
    window.Selectr = require('mobius1-selectr');
    window.Toastedjs = require('toastedjs');
    window.Swal = require('sweetalert2');
    window.Flowbite = require('flowbite/dist/datepicker.js');
    window.Base = require('./other/base');
}
catch(e){}

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

