"use strict";

class Base
{
    checkedHour()
    {
        if (
            document.body.contains(document.querySelector('#hour_approbation'))
            && document.body.contains(document.querySelector('#div_effects'))
        ) {
            let div_effects = document.querySelector('#div_effects');
            let hour_approbation = document.querySelector('#hour_approbation');
            let input_approbation = document.querySelector('#approbation');

            if (input_approbation.checked) {
                div_effects.classList.remove('hidden');
                hour_approbation.classList.remove('hidden');
            }

            input_approbation.addEventListener('change', (event) => {
                if (event.target.checked) {
                    div_effects.classList.remove('hidden');
                    hour_approbation.classList.remove('hidden');
                } else {
                    div_effects.classList.add('hidden');
                    hour_approbation.classList.add('hidden');
                }
            });
        }
    }

    chooseAsset()
    {
        if (
            document.body.contains(document.querySelector('#asset_id'))
            && document.body.contains(document.querySelector('#div_owner'))
        ) {
            let asset = document.querySelector("#asset_id");
            let div_owner = document.querySelector("#div_owner");

            try {
                new Selectr(asset);
            } catch (error) {
                console.log(error);
            }

            asset.addEventListener('change', (event) => {
                let value = asset.value;
                let url = `/items/owner/${value}`;

                axios({
                    body: JSON.stringify({ value }),
                    method: 'put',
                    url: url
                })
                .then((response) => {
                    div_owner.classList.remove('hidden');
                    div_owner.innerHTML = response.data;
                })
                .catch((error) => {
                    let message;

                    switch(error.response.status)
                    {
                        case 400:
                            message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se puede mostrar el propietario, por favor vuelve a intentarlo.</p>';
                            break;

                        case 403:
                            message = '<p class="m-0">Acceso denegado.</p>';
                            break;

                        case 404:
                            message = '<p class="m-0">No se puede mostrar el propietario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 405:
                            message = '<p class="m-0">No se puede mostrar el propietario porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;

                        case 500:
                            message = '<p class="m-0">No se puede mostrar el propietario porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 504:
                            message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se puede mostrar el propietario, por favor vuelve a intentarlo.</p>';
                            break;

                        case 509:
                            message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se puede mostrar el propietario, por favor vuelve a intentarlo.</p>';
                            break;

                        default:
                            message = '<p class="m-0">No se puede mostrar el propietario porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;
                    }

                    this.showToasted("alive", "error", message);
                });
            })
        }
    }

    chooseControl()
    {
        if (document.body.contains(document.querySelector('#control_description'))) {
            let control_description = document.querySelector("#control_description");
            let div_effects = document.querySelector("#div_effects");

            control_description.addEventListener('input', (event) => {

                let text = control_description.value;

                if (text != "") {
                    div_effects.classList.remove('hidden');
                    control_description.required = true;
                } else {
                    div_effects.classList.add('hidden');
                    control_description.required = false;
                }
            })
        }
    }

    chooseThreat()
    {
        if (
            document.body.contains(document.querySelector('#asset_id'))
            && document.body.contains(document.querySelector('#div_threat'))
        ) {
            let asset = document.querySelector("#asset_id");
            let div_threat = document.querySelector("#div_threat");

            try {
                new Selectr(asset);
            } catch (error) {
                console.log(error);
            }

            asset.addEventListener('change', (event) => {
                let value = asset.value;
                let url = `/risks/threat/${value}`;

                axios({
                    body: JSON.stringify({ value }),
                    method: 'put',
                    url: url
                })
                .then((response) => {
                    div_threat.classList.remove('hidden');
                    div_threat.innerHTML = response.data;

                    let threat = document.querySelector("#threat_id");

                    try {
                        new Selectr(threat);
                    } catch (error) {
                        console.log(error);
                    }
                })
                .catch((error) => {

                    let message;

                    switch(error.response.status)
                    {
                        case 400:
                            message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se puede elegir la amenaza, por favor vuelve a intentarlo.</p>';
                            break;

                        case 403:
                            message = '<p class="m-0">Acceso denegado.</p>';
                            break;

                        case 404:
                            message = '<p class="m-0">No se puede elegir la amenaza porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 405:
                            message = '<p class="m-0">No se puede elegir la amenaza porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;

                        case 500:
                            message = '<p class="m-0">No se puede elegir la amenaza porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 504:
                            message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se puede elegir la amenaza, por favor vuelve a intentarlo.</p>';
                            break;

                        case 509:
                            message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se puede elegir la amenaza, por favor vuelve a intentarlo.</p>';
                            break;

                        default:
                            message = '<p class="m-0">No se puede elegir la amenaza porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;
                    }

                    this.showToasted("alive", "error", message);
                });
            })
        }
    }

    closeMenu() {
        window.onclick = function(event) {
            if (!event.target.matches('.drop-button') && !event.target.matches('.drop-search')) {

                let dropdowns = document.getElementsByClassName("dropdownlist");

                for (let i = 0; i < dropdowns.length; i++) {

                    let openDropdown = dropdowns[i];

                    if (!openDropdown.classList.contains('invisible')) {
                        openDropdown.classList.add('invisible');
                    }
                }
            }
        }
    }

    configModeAuth(main, icon_sun, icon_moon, mode)
    {
        let navbar = document.querySelector('#navbar');
        let sidebar = document.querySelector('#sidebar');

        if (mode === 'dark') {
            main.classList.add('bg-black');
            main.classList.remove('bg-white');

            icon_sun.classList.add('opacity-25');
            icon_moon.classList.remove('opacity-25');

            navbar.classList.add('bg-cyan-900');
            navbar.classList.remove('bg-mint-400');

            sidebar.classList.add('bg-cyan-900');
            sidebar.classList.remove('bg-mint-400');

            let numbers_array = [ 50, 100, 200, 300, 400, 500, 600, 700, 800, 900 ];

            numbers_array.forEach(function (value_external, i) {
                let array_background = document.body.querySelectorAll(`.bg-mint-${value_external}`);
                let array_background_hover = document.body.querySelectorAll(`.hover\\:bg-mint-${value_external}`);
                let array_border = document.body.querySelectorAll(`.border-mint-${value_external}`);
                let array_border_after = document.body.querySelectorAll(`.after\\:border-mint-${value_external}`);
                let array_border_hover = document.body.querySelectorAll(`.hover\\:border-mint-${value_external}`);
                let array_focus = document.body.querySelectorAll(`.focus\\:ring-mint-${value_external}`);
                let array_peer_checked = document.body.querySelectorAll(`.peer-checked\\:bg-mint-${value_external}`);
                let array_peer_focus_ring = document.body.querySelectorAll(`.peer-focus\\:ring-mint-${value_external}`);
                let array_scrollbar_thumb = document.body.querySelectorAll(`.scrollbar-thumb-mint-${value_external}`);
                let array_scrollbar_track = document.body.querySelectorAll(`.scrollbar-track-mint-${value_external}`);
                let array_text = document.body.querySelectorAll(`.text-mint-${value_external}`);
                let array_text_hover = document.body.querySelectorAll(`.hover\\:text-mint-${value_external}`);

                array_background.forEach(function (value_internal, i) {
                    value_internal.classList.add(`bg-cyan-${value_external}`);
                    value_internal.classList.remove(`bg-mint-${value_external}`);
                });

                array_background_hover.forEach(function (value_internal, i) {
                    value_internal.classList.add(`hover:bg-cyan-${value_external}`);
                    value_internal.classList.remove(`hover:bg-mint-${value_external}`);
                });

                array_border.forEach(function (value_internal, i) {
                    value_internal.classList.add(`border-cyan-${value_external}`);
                    value_internal.classList.remove(`border-mint-${value_external}`);
                });

                array_border_after.forEach(function (value_internal, i) {
                    value_internal.classList.add(`after:border-cyan-${value_external}`);
                    value_internal.classList.remove(`after:border-mint-${value_external}`);
                });

                array_border_hover.forEach(function (value_internal, i) {
                    value_internal.classList.add(`hover:border-cyan-${value_external}`);
                    value_internal.classList.remove(`hover:border-mint-${value_external}`);
                });

                array_focus.forEach(function (value_internal, i) {
                    value_internal.classList.add(`focus:ring-cyan-${value_external}`);
                    value_internal.classList.remove(`focus:ring-mint-${value_external}`);
                });

                array_peer_checked.forEach(function (value_internal, i) {
                    value_internal.classList.add(`peer-checked:bg-cyan-${value_external}`);
                    value_internal.classList.remove(`peer-checked:bg-mint-${value_external}`);
                });

                array_peer_focus_ring.forEach(function (value_internal, i) {
                    value_internal.classList.add(`peer-focus:ring-cyan-${value_external}`);
                    value_internal.classList.remove(`peer-focus:ring-mint-${value_external}`);
                });

                array_scrollbar_thumb.forEach(function (value_internal, i) {
                    value_internal.classList.add(`scrollbar-thumb-cyan-${value_external}`);
                    value_internal.classList.remove(`scrollbar-thumb-mint-${value_external}`);
                });

                array_scrollbar_track.forEach(function (value_internal, i) {
                    value_internal.classList.add(`scrollbar-track-cyan-${value_external}`);
                    value_internal.classList.remove(`scrollbar-track-mint-${value_external}`);
                });

                array_text.forEach(function (value_internal, i) {
                    value_internal.classList.add(`text-cyan-${value_external}`);
                    value_internal.classList.remove(`text-mint-${value_external}`);
                });

                array_text_hover.forEach(function (value_internal, i) {
                    value_internal.classList.add(`hover:text-cyan-${value_external}`);
                    value_internal.classList.remove(`hover:text-mint-${value_external}`);
                });
            });

            localStorage.setItem('mode', 'dark');
        } else {
            main.classList.add('bg-white');
            main.classList.remove('bg-black');

            icon_moon.classList.add('opacity-25');
            icon_sun.classList.remove('opacity-25');

            navbar.classList.add('bg-mint-400');
            navbar.classList.remove('bg-cyan-900');

            sidebar.classList.add('bg-mint-400');
            sidebar.classList.remove('bg-cyan-900');

            let numbers_array = [ 50, 100, 200, 300, 400, 500, 600, 700, 800, 900 ];

            numbers_array.forEach(function (value_external, i) {
                let array_background = document.body.querySelectorAll(`.bg-cyan-${value_external}`);
                let array_background_hover = document.body.querySelectorAll(`.hover\\:bg-cyan-${value_external}`);
                let array_border = document.body.querySelectorAll(`.border-cyan-${value_external}`);
                let array_border_after = document.body.querySelectorAll(`.after\\:border-cyan-${value_external}`);
                let array_border_hover = document.body.querySelectorAll(`.hover\\:border-cyan-${value_external}`);
                let array_focus = document.body.querySelectorAll(`.focus\\:ring-cyan-${value_external}`);
                let array_peer_checked = document.body.querySelectorAll(`.peer-checked\\:bg-cyan-${value_external}`);
                let array_peer_focus_ring = document.body.querySelectorAll(`.peer-focus\\:ring-cyan-${value_external}`);
                let array_scrollbar_thumb = document.body.querySelectorAll(`.scrollbar-thumb-cyan-${value_external}`);
                let array_scrollbar_track = document.body.querySelectorAll(`.scrollbar-track-cyan-${value_external}`);
                let array_text = document.body.querySelectorAll(`.text-cyan-${value_external}`);
                let array_text_hover = document.body.querySelectorAll(`.hover\\:text-cyan-${value_external}`);

                array_background.forEach(function (value_internal, i) {
                    value_internal.classList.add(`bg-mint-${value_external}`);
                    value_internal.classList.remove(`bg-cyan-${value_external}`);
                });

                array_background_hover.forEach(function (value_internal, i) {
                    value_internal.classList.add(`hover:bg-mint-${value_external}`);
                    value_internal.classList.remove(`hover:bg-cyan-${value_external}`);
                });

                array_border.forEach(function (value_internal, i) {
                    value_internal.classList.add(`border-mint-${value_external}`);
                    value_internal.classList.remove(`border-cyan-${value_external}`);
                });

                array_border_after.forEach(function (value_internal, i) {
                    value_internal.classList.add(`after:border-mint-${value_external}`);
                    value_internal.classList.remove(`after:border-cyan-${value_external}`);
                });

                array_border_hover.forEach(function (value_internal, i) {
                    value_internal.classList.add(`hover:border-mint-${value_external}`);
                    value_internal.classList.remove(`hover:border-cyan-${value_external}`);
                });

                array_focus.forEach(function (value_internal, i) {
                    value_internal.classList.add(`focus:ring-mint-${value_external}`);
                    value_internal.classList.remove(`focus:ring-cyan-${value_external}`);
                });

                array_peer_checked.forEach(function (value_internal, i) {
                    value_internal.classList.add(`peer-checked:bg-mint-${value_external}`);
                    value_internal.classList.remove(`peer-checked:bg-cyan-${value_external}`);
                });

                array_peer_focus_ring.forEach(function (value_internal, i) {
                    value_internal.classList.add(`peer-focus:ring-mint-${value_external}`);
                    value_internal.classList.remove(`peer-focus:ring-cyan-${value_external}`);
                });

                array_scrollbar_thumb.forEach(function (value_internal, i) {
                    value_internal.classList.add(`scrollbar-thumb-mint-${value_external}`);
                    value_internal.classList.remove(`scrollbar-thumb-cyan-${value_external}`);
                });

                array_scrollbar_track.forEach(function (value_internal, i) {
                    value_internal.classList.add(`scrollbar-track-mint-${value_external}`);
                    value_internal.classList.remove(`scrollbar-track-cyan-${value_external}`);
                });

                array_text.forEach(function (value_internal, i) {
                    value_internal.classList.add(`text-mint-${value_external}`);
                    value_internal.classList.remove(`text-cyan-${value_external}`);
                });

                array_text_hover.forEach(function (value_internal, i) {
                    value_internal.classList.add(`hover:text-mint-${value_external}`);
                    value_internal.classList.remove(`hover:text-cyan-${value_external}`);
                });
            });

            localStorage.setItem('mode', 'light');
        }
    }

    configModeGuest(main, icon_sun, icon_moon, mode)
    {
        if (mode === 'dark') {
            main.classList.add('bg-black');
            main.classList.remove('bg-mint-500');

            icon_sun.classList.add('opacity-25');
            icon_moon.classList.remove('opacity-25');

            let text_array = document.body.querySelectorAll('.text-white');
            let numbers_array = [ 50, 100, 200, 300, 400, 500, 600, 700, 800, 900 ];

            numbers_array.forEach(function (value_external, i) {
                let array_background = document.body.querySelectorAll(`.bg-mint-${value_external}`);
                let array_background_hover = document.body.querySelectorAll(`.hover\\:bg-mint-${value_external}`);
                let array_border = document.body.querySelectorAll(`.border-mint-${value_external}`);
                let array_border_after = document.body.querySelectorAll(`.after\\:border-mint-${value_external}`);
                let array_border_hover = document.body.querySelectorAll(`.hover\\:border-mint-${value_external}`);
                let array_focus = document.body.querySelectorAll(`.focus\\:ring-mint-${value_external}`);
                let array_peer_checked = document.body.querySelectorAll(`.peer-checked\\:bg-mint-${value_external}`);
                let array_peer_focus_ring = document.body.querySelectorAll(`.peer-focus\\:ring-mint-${value_external}`);
                let array_text = document.body.querySelectorAll(`.text-mint-${value_external}`);

                array_background.forEach(function (value_internal, i) {
                    value_internal.classList.add(`bg-cyan-${value_external}`);
                    value_internal.classList.remove(`bg-mint-${value_external}`);
                });

                array_background_hover.forEach(function (value_internal, i) {
                    value_internal.classList.add(`hover:bg-cyan-${value_external}`);
                    value_internal.classList.remove(`hover:bg-mint-${value_external}`);
                });

                array_border.forEach(function (value_internal, i) {
                    value_internal.classList.add(`border-cyan-${value_external}`);
                    value_internal.classList.remove(`border-mint-${value_external}`);
                });

                array_border_after.forEach(function (value_internal, i) {
                    value_internal.classList.add(`after:border-cyan-${value_external}`);
                    value_internal.classList.remove(`after:border-mint-${value_external}`);
                });

                array_border_hover.forEach(function (value_internal, i) {
                    value_internal.classList.add(`hover:border-cyan-${value_external}`);
                    value_internal.classList.remove(`hover:border-mint-${value_external}`);
                });

                array_focus.forEach(function (value_internal, i) {
                    value_internal.classList.add(`focus:ring-cyan-${value_external}`);
                    value_internal.classList.remove(`focus:ring-mint-${value_external}`);
                });

                array_peer_checked.forEach(function (value_internal, i) {
                    value_internal.classList.add(`peer-checked:bg-cyan-${value_external}`);
                    value_internal.classList.remove(`peer-checked:bg-mint-${value_external}`);
                });

                array_peer_focus_ring.forEach(function (value_internal, i) {
                    value_internal.classList.add(`peer-focus:ring-cyan-${value_external}`);
                    value_internal.classList.remove(`peer-focus:ring-mint-${value_external}`);
                });

                array_text.forEach(function (value_internal, i) {
                    value_internal.classList.add(`text-cyan-${value_external}`);
                    value_internal.classList.remove(`text-mint-${value_external}`);
                });
            });

            text_array.forEach(function (value, i) {
                value.classList.add('text-cyan-500');
                value.classList.remove('text-white');
            });

            localStorage.setItem('mode', 'dark');
        } else {
            main.classList.add('bg-mint-500');
            main.classList.remove('bg-black');

            icon_moon.classList.add('opacity-25');
            icon_sun.classList.remove('opacity-25');

            let text_array = document.body.querySelectorAll('.text-cyan-500');
            let numbers_array = [ 50, 100, 200, 300, 400, 500, 600, 700, 800, 900 ];

            numbers_array.forEach(function (value_external, i) {
                let array_background = document.body.querySelectorAll(`.bg-cyan-${value_external}`);
                let array_background_hover = document.body.querySelectorAll(`.hover\\:bg-cyan-${value_external}`);
                let array_border = document.body.querySelectorAll(`.border-cyan-${value_external}`);
                let array_border_after = document.body.querySelectorAll(`.after\\:border-cyan-${value_external}`);
                let array_border_hover = document.body.querySelectorAll(`.hover\\:border-cyan-${value_external}`);
                let array_focus = document.body.querySelectorAll(`.focus\\:ring-cyan-${value_external}`);
                let array_peer_checked = document.body.querySelectorAll(`.peer-checked\\:bg-cyan-${value_external}`);
                let array_peer_focus_ring = document.body.querySelectorAll(`.peer-focus\\:ring-cyan-${value_external}`);
                let array_text = document.body.querySelectorAll(`.text-cyan-${value_external}`);

                array_background.forEach(function (value_internal, i) {
                    value_internal.classList.add(`bg-mint-${value_external}`);
                    value_internal.classList.remove(`bg-cyan-${value_external}`);
                });

                array_background_hover.forEach(function (value_internal, i) {
                    value_internal.classList.add(`hover:bg-mint-${value_external}`);
                    value_internal.classList.remove(`hover:bg-cyan-${value_external}`);
                });

                array_border.forEach(function (value_internal, i) {
                    value_internal.classList.add(`border-mint-${value_external}`);
                    value_internal.classList.remove(`border-cyan-${value_external}`);
                });

                array_border_after.forEach(function (value_internal, i) {
                    value_internal.classList.add(`after:border-mint-${value_external}`);
                    value_internal.classList.remove(`after:border-cyan-${value_external}`);
                });

                array_border_hover.forEach(function (value_internal, i) {
                    value_internal.classList.add(`hover:border-mint-${value_external}`);
                    value_internal.classList.remove(`hover:border-cyan-${value_external}`);
                });

                array_focus.forEach(function (value_internal, i) {
                    value_internal.classList.add(`focus:ring-mint-${value_external}`);
                    value_internal.classList.remove(`focus:ring-cyan-${value_external}`);
                });

                array_peer_checked.forEach(function (value_internal, i) {
                    value_internal.classList.add(`peer-checked:bg-mint-${value_external}`);
                    value_internal.classList.remove(`peer-checked:bg-cyan-${value_external}`);
                });

                array_peer_focus_ring.forEach(function (value_internal, i) {
                    value_internal.classList.add(`peer-focus:ring-mint-${value_external}`);
                    value_internal.classList.remove(`peer-focus:ring-cyan-${value_external}`);
                });

                array_text.forEach(function (value_internal, i) {
                    value_internal.classList.add(`text-mint-${value_external}`);
                    value_internal.classList.remove(`text-cyan-${value_external}`);
                });
            });

            text_array.forEach(function (value, i) {
                value.classList.add('text-white');
                value.classList.remove('text-cyan-500');
            });

            localStorage.setItem('mode', 'light');
        }
    }

    destroyAsset()
    {
        document.querySelectorAll('.destroyAsset').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar este activo?</p>");

                question.then((result) => {
                    if (result.value)
                    {
                        axios({
                            body: JSON.stringify({ id }),
                            method: 'delete',
                            url: `/assets/${id}`
                        })
                        .then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>${ response.data }</p>`);
                            setTimeout(() => location.href = `/assets`, 1500);
                        })
                        .catch((error) => {

                            let message;

                            switch(error.response.status)
                            {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el activo, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado el activo porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado el activo porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado el activo porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el activo, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el activo, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado el activo porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "error", "<p class='m-0 text-center'>El activo seleccionado no ha sido afectado.</p>");
                    }
                })
            })
        })
    }

    destroyControl()
    {
        document.querySelectorAll('.destroyControl').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar este control?</p>");

                question.then((result) => {
                    if (result.value)
                    {
                        axios({
                            body: JSON.stringify({ id }),
                            method: 'delete',
                            url: `/controls/${id}`
                        })
                        .then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>${ response.data }</p>`);
                            setTimeout(() => location.href = `/controls`, 1500);
                        })
                        .catch((error) => {

                            let message;

                            switch(error.response.status)
                            {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el control, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado el control porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado el control porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado el control porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el control, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el control, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado el control porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "error", "<p class='m-0 text-center'>El control seleccionado no ha sido afectado.</p>");
                    }
                })
            })
        })
    }

    destroyIncident()
    {
        document.querySelectorAll('.destroyIncident').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar esta incidencia?</p>");

                question.then((result) => {
                    if (result.value)
                    {
                        axios({
                            body: JSON.stringify({ id }),
                            method: 'delete',
                            url: `/incidents/${id}`
                        })
                        .then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>${ response.data }</p>`);
                            setTimeout(() => location.href = `/incidents`, 1500);
                        })
                        .catch((error) => {

                            let message;

                            switch(error.response.status)
                            {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la incidencia, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado la incidencia porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado la incidencia porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado la incidencia porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la incidencia, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la incidencia, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado la incidencia porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "error", "<p class='m-0 text-center'>La incidencia seleccionada no ha sido afectada.</p>");
                    }
                })
            })
        })
    }

    destroyItem()
    {
        document.querySelectorAll('.destroyItem').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar este item?</p>");

                question.then((result) => {
                    if (result.value)
                    {
                        axios({
                            body: JSON.stringify({ id }),
                            method: 'delete',
                            url: `/items/${id}`
                        })
                        .then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>${ response.data }</p>`);
                            setTimeout(() => location.href = `/items`, 1500);
                        })
                        .catch((error) => {

                            let message;

                            switch(error.response.status)
                            {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el item, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado el item porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado el item porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado el item porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el item, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el item, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado el item porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "error", "<p class='m-0 text-center'>El item seleccionado no ha sido afectado.</p>");
                    }
                })
            })
        })
    }

    destroyKind()
    {
        document.querySelectorAll('.destroyKind').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar este tipo?</p>");

                question.then((result) => {
                    if (result.value)
                    {
                        axios({
                            body: JSON.stringify({ id }),
                            method: 'delete',
                            url: `/kinds/${id}`
                        })
                        .then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>${ response.data }</p>`);
                            setTimeout(() => location.href = `/kinds`, 1500);
                        })
                        .catch((error) => {

                            let message;

                            switch(error.response.status)
                            {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el tipo, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado el tipo porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado el tipo porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado el tipo porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el tipo, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el tipo, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado el tipo porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "error", "<p class='m-0 text-center'>El tipo seleccionado no ha sido afectado.</p>");
                    }
                })
            })
        })
    }

    destroyPosition()
    {
        document.querySelectorAll('.destroyPosition').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar este cargo?</p>");

                question.then((result) => {
                    if (result.value)
                    {
                        axios({
                            body: JSON.stringify({ id }),
                            method: 'delete',
                            url: `/positions/${id}`
                        })
                        .then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>${ response.data }</p>`);
                            setTimeout(() => location.href = `/positions`, 1500);
                        })
                        .catch((error) => {

                            let message;

                            switch(error.response.status)
                            {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el cargo, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado el cargo porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado el cargo porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado el cargo porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el cargo, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el cargo, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado el cargo porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "error", "<p class='m-0 text-center'>El cargo seleccionado no ha sido afectado.</p>");
                    }
                })
            })
        })
    }

    destroyProcess()
    {
        document.querySelectorAll('.destroyProcess').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar este proceso?</p>");

                question.then((result) => {
                    if (result.value)
                    {
                        axios({
                            body: JSON.stringify({ id }),
                            method: 'delete',
                            url: `/processes/${id}`
                        })
                        .then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>${ response.data }</p>`);
                            setTimeout(() => location.href = `/processes`, 1500);
                        })
                        .catch((error) => {

                            let message;

                            switch(error.response.status)
                            {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el proceso, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado el proceso porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado el proceso porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado el proceso porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el proceso, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el proceso, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado el proceso porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "error", "<p class='m-0 text-center'>El proceso seleccionado no ha sido afectado.</p>");
                    }
                })
            })
        })
    }

    destroyRisk()
    {
        document.querySelectorAll('.destroyRisk').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar este riesgo?</p>");

                question.then((result) => {
                    if (result.value)
                    {
                        axios({
                            body: JSON.stringify({ id }),
                            method: 'delete',
                            url: `/risks/${id}`
                        })
                        .then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>${ response.data }</p>`);
                            setTimeout(() => location.href = `/risks`, 1500);
                        })
                        .catch((error) => {

                            let message;

                            switch(error.response.status)
                            {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el riesgo, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado el riesgo porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado el riesgo porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado el riesgo porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el riesgo, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el riesgo, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado el riesgo porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "error", "<p class='m-0 text-center'>El riesgo seleccionado no ha sido afectado.</p>");
                    }
                })
            })
        })
    }

    destroyRole()
    {
        document.querySelectorAll('.destroyRole').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar este rol?</p>");

                question.then((result) => {
                    if (result.value)
                    {
                        axios({
                            body: JSON.stringify({ id }),
                            method: 'delete',
                            url: `/roles/${id}`
                        })
                        .then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>${ response.data }</p>`);
                            setTimeout(() => location.href = `/roles`, 1500);
                        })
                        .catch((error) => {

                            let message;

                            switch(error.response.status)
                            {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el rol, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado el rol porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado el rol porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado el rol porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el rol, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el rol, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado el rol porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "error", "<p class='m-0 text-center'>El rol seleccionado no ha sido afectado.</p>");
                    }
                })
            })
        })
    }

    destroyThreat()
    {
        document.querySelectorAll('.destroyThreat').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar este amenaza?</p>");

                question.then((result) => {
                    if (result.value)
                    {
                        axios({
                            body: JSON.stringify({ id }),
                            method: 'delete',
                            url: `/threats/${id}`
                        })
                        .then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>${ response.data }</p>`);
                            setTimeout(() => location.href = `/threats`, 1500);
                        })
                        .catch((error) => {

                            let message;

                            switch(error.response.status)
                            {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la amenaza, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado la amenaza porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado la amenaza porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado la amenaza porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la amenaza, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la amenaza, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado la amenaza porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "error", "<p class='m-0 text-center'>La amenaza seleccionada no ha sido afectada.</p>");
                    }
                })
            })
        })
    }

    destroyUnit()
    {
        document.querySelectorAll('.destroyUnit').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar esta área?</p>");

                question.then((result) => {
                    if (result.value)
                    {
                        axios({
                            body: JSON.stringify({ id }),
                            method: 'delete',
                            url: `/units/${id}`
                        })
                        .then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>${ response.data }</p>`);
                            setTimeout(() => location.href = `/units`, 1500);
                        })
                        .catch((error) => {

                            let message;

                            switch(error.response.status)
                            {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el área, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado el área porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado el área porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado el área porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el área, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el área, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado el área porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "error", "<p class='m-0 text-center'>El área seleccionada no ha sido afectada.</p>");
                    }
                })
            })
        })
    }

    destroyUser()
    {
        document.querySelectorAll('.destroyUser').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar este usuario?</p>");

                question.then((result) => {
                    if (result.value)
                    {
                        axios({
                            body: JSON.stringify({ id }),
                            method: 'delete',
                            url: `/users/${id}`
                        })
                        .then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>${ response.data }</p>`);
                            setTimeout(() => location.href = `/users`, 1500);
                        })
                        .catch((error) => {

                            let message;

                            switch(error.response.status)
                            {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el usuario, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado el usuario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado el usuario porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado el usuario porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el usuario, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el usuario, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado el usuario porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "error", "<p class='m-0 text-center'>El usuario seleccionado no ha sido afectado.</p>");
                    }
                })
            })
        })
    }

    disableForm()
    {
        document.querySelectorAll('.form-main').forEach(form => {
            form.addEventListener('submit', event => {
                document.querySelectorAll('.btn-form').forEach(button => {
                    button.disabled = true;
                })
            })
        })
    }

    manageMode()
    {
        /** Declara las constantes que gestionan el botón que gestiona el modo 'claro/oscuro' */
        const mode = document.querySelector('#mode');
        const icon_sun = document.querySelector('#icon_sun');
        const icon_moon = document.querySelector('#icon_moon');

        /** Si existe en el localstorage la variable 'mode' */
        if (localStorage.getItem("mode") !== null) {
            /** Si la variable del localstorage esta con valor 'dark' */
            if (localStorage.getItem("mode") === "dark") {
                mode.checked = true;

                /** Para las páginas donde no se ha iniciado sesión */
                if (document.body.contains(document.querySelector('#main-guest')) ) {
                    let main = document.querySelector('#main-guest');
                    this.configModeGuest(main, icon_sun, icon_moon, "dark");

                    /** Para las páginas donde hay una sesión activa */
                } else {
                    let main = document.querySelector('#main-auth');
                    this.configModeAuth(main, icon_sun, icon_moon, "dark");
                }

                /** Si la variable del localstorage esta con valor 'light' */
            } else {
                mode.checked = false;

                /** Para las páginas donde no se ha iniciado sesión */
                if (document.body.contains(document.querySelector('#main-guest')) ) {
                    let main = document.querySelector('#main-guest');
                    this.configModeGuest(main, icon_sun, icon_moon, "white");

                  /** Para las páginas donde hay una sesión activa */
                } else {
                    let main = document.querySelector('#main-auth');
                    this.configModeAuth(main, icon_sun, icon_moon, "white");
                }
            }

            /** Si no existe en el localstorage la variable "mode" */
        } else {
            localStorage.setItem('mode', 'light');
            mode.checked = false;
        }

        /** Al dar click en el botón que gestiona el modo 'claro/oscuro' */
        mode.addEventListener('click', () => {
            /** Para las páginas donde no se ha iniciado sesión */
            if (document.body.contains(document.querySelector('#main-guest')) )
            {
                let main = document.querySelector('#main-guest');

                if ( mode.checked == true ) {
                    this.configModeGuest(main, icon_sun, icon_moon, "dark");
                } else {
                    this.configModeGuest(main, icon_sun, icon_moon, "white");
                }

                /** Para las páginas donde hay una sesión activa */
            } else {
                let main = document.querySelector('#main-auth');

                if ( mode.checked == true ) {
                    this.configModeAuth(main, icon_sun, icon_moon, "dark");
                } else {
                    this.configModeAuth(main, icon_sun, icon_moon, "white");
                }
            }
        });
    }

    recalculateRisks()
    {
        if (document.body.contains(document.querySelector('#recalculateRisks')) )
        {
            let button = document.querySelector('#recalculateRisks');

            button.addEventListener('click', event => {
                let question = this.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea recalcular los riesgos?</p>");

                question.then((result) => {
                    if (result.value)
                    {
                        axios({
                            method: 'put',
                            url: `/risks/recalculate/effect`
                        })
                        .then((response) => {
                            this.showToasted("alive", "success", `<p class='p-0'>Los riesgos han sido recalculados.</p>`);
                            setTimeout(() => window.location.reload(), 1500);
                        })
                        .catch((error) => {

                            let message;

                            switch(error.response.status)
                            {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se han recalculado los riesgos, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se han recalculado los riesgos porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se han recalculado los riesgos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se han recalculado los riesgos porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recalculado los riesgos, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible.No se han recalculado los riesgos, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se han recalculado los riesgos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.showToasted("alive", "error", message);
                        });
                    } else {
                        this.showToasted("alive", "success", "<p class='m-0 text-center'>Los riesgos no han sido afectados.</p>");
                    }
                })
            })
        }
    }

    replaceEnterSpaces() {
        document.querySelectorAll('.enterSpace').forEach(item => {
            item.addEventListener("keypress", function(event) {
                if (event.key === "Enter" || (event.key === 'v' && event.ctrlKey)) {
                    let text = item.value.replace(/(\r\n|\n|\r)/gm, " ");
                    item.value = text;
                }
            });

            item.addEventListener("keydown", function(event) {
                if (event.key === "Enter" || (event.key === 'v' && event.ctrlKey)) {
                    let text = item.value.replace(/(\r\n|\n|\r)/gm, " ");
                    item.value = text;
                }
            });
        })
    }

    selectConfig(){
        document.querySelectorAll('.selectr').forEach(item => {
          let id = item.getAttribute("id");

            try {
                new Selectr(document.getElementById(id));
            } catch (error) {
                console.log(error);
            }
        })
    }

    showAsset()
    {
        document.querySelectorAll('.showAsset').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");

                axios({
                    body: JSON.stringify({ id }),
                    method: 'put',
                    url: `/assets/show/${id}`
                })
                .then((response) => {
                    let message =
                        '<div class=text-left>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold">Nombre: </span>'+response.data.name+'</p>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold text-justify">Descripción: </span>'+response.data.description+'</p>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold">Ubicación: </span>'+response.data.location+'</p>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold">Tipo: </span>'+response.data.kind+'</p>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold">Estado: </span>'+response.data.status+'</p>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold">Dueño: </span>'+response.data.owner+'</p>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold">Creado por: </span>'+response.data.user+'</p>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold">Fecha de Creación: </span>'+response.data.created_at+'</p>'
                        +' '+
                        '</div>';

                    let title = '<p class="font-semibold m-0 text-lg">Resumen del activo</p>';

                    this.showSwal("#22C55E", message, "success", title);
                })
                .catch((error) => {

                    let message;

                    switch(error.response.status)
                    {
                        case 400:
                            message = '<p class="m-0 text-sm">No se ha hecho la petición de forma correcta. No se han recuperado los datos del activo, por favor vuelve a intentarlo.</p>';
                            break;

                        case 403:
                            message = '<p class="m-0 text-sm">Acceso denegado.</p>';
                            break;

                        case 404:
                            message = '<p class="m-0 text-sm">No se han recuperado los datos del activo porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 405:
                            message = '<p class="m-0 text-sm">No se han recuperado los datos del activo porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;

                        case 500:
                            message = '<p class="m-0 text-sm">No se han recuperado los datos del activo porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 504:
                            message = '<p class="m-0 text-sm">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos del activo, por favor vuelve a intentarlo.</p>';
                            break;

                        case 509:
                            message = '<p class="m-0 text-sm">Se ha superado el ancho de banda disponible. No se han recuperado los datos del activo, por favor vuelve a intentarlo.</p>';
                            break;

                        default:
                            message = '<p class="m-0 text-sm">No se han recuperado los datos del activo porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;
                    }

                    let title = '<p class="font-semibold m-0 text-center">Atención</p>';

                    this.showSwal("#FFA500", message, "error", title);
                });
            })
        })
    }

    showItem()
    {
        document.querySelectorAll('.showItem').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");

                axios({
                    body: JSON.stringify({ id }),
                    method: 'put',
                    url: `/items/show/${id}`
                })
                .then((response) => {
                    let message =
                        '<div class=text-left>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold">Activo: </span>'+response.data.asset+'</p>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold text-justify">Proceso: </span>'+response.data.process+'</p>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold">Descripción: </span>'+response.data.description+'</p>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold">Comentarios: </span>'+response.data.comments+'</p>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold">Confidencialidad: </span>'+response.data.confidentiality+'</p>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold">Integridad: </span>'+response.data.integrity+'</p>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold">Disponibilidad: </span>'+response.data.availability+'</p>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold">Valor: </span>'+response.data.value+'</p>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold">Propietario: </span>'+response.data.owner+'</p>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold">Creado por: </span>'+response.data.user+'</p>'
                        +' '+
                        '<p class="m-0 text-sm"><span class="font-semibold">Fecha de Creación: </span>'+response.data.created_at+'</p>'
                        +' '+
                        '</div>';

                    let title = '<p class="font-semibold m-0 text-lg">Resumen del item</p>';

                    this.showSwal("#22C55E", message, "success", title);
                })
                .catch((error) => {

                    let message;

                    switch(error.response.status)
                    {
                        case 400:
                            message = '<p class="m-0 text-sm">No se ha hecho la petición de forma correcta. No se han recuperado los datos del item, por favor vuelve a intentarlo.</p>';
                            break;

                        case 403:
                            message = '<p class="m-0 text-sm">Acceso denegado.</p>';
                            break;

                        case 404:
                            message = '<p class="m-0 text-sm">No se han recuperado los datos del item porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 405:
                            message = '<p class="m-0 text-sm">No se han recuperado los datos del item porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;

                        case 500:
                            message = '<p class="m-0 text-sm">No se han recuperado los datos del item porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 504:
                            message = '<p class="m-0 text-sm">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos del item, por favor vuelve a intentarlo.</p>';
                            break;

                        case 509:
                            message = '<p class="m-0 text-sm">Se ha superado el ancho de banda disponible. No se han recuperado los datos del item, por favor vuelve a intentarlo.</p>';
                            break;

                        default:
                            message = '<p class="m-0 text-sm">No se han recuperado los datos del item porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;
                    }

                    let title = '<p class="font-semibold m-0 text-center">Atención</p>';

                    this.showSwal("#FFA500", message, "error", title);
                });
            })
        })
    }

    showRole()
    {
        document.querySelectorAll('.showRole').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");

                axios({
                    body: JSON.stringify({ id }),
                    method: 'put',
                    url: `/roles/show/${id}`
                })
                .then((response) => {
                    let permissions = (response.data.permissions.length != 0) ? `` : 'No tiene permisos asignados';

                    response.data.permissions.forEach(function (value, index) {
                       permissions += `<div class="px-3 text-justify text-sm">`
                        +` `+
                        `<span class="font-semibold">${ index+1 }.</span>`
                        +` `+
                        `<span>${ value.name }</span>`
                        +` `+
                        `<span class="font-semibold text-slate-500">(${ value.description })</span>`
                        +` `+
                        `</div>`;
                    });

                    let message =
                        `<div class=text-left>`
                        +` `+
                        `<p class="m-0 text-sm"><span class="font-semibold">Nombre: </span>${response.data.name}</p>`
                        +` `+
                        `<p class="m-0 text-sm"><span class="font-semibold text-justify">Descripción: </span>${response.data.description}</p>`
                        +` `+
                        `<p class="m-0 text-sm"><span class="font-semibold">Fecha de Creación: </span>${response.data.created_at}</p>`
                        +` `+
                        `<p class="m-0 text-sm"><span class="font-semibold">Permisos: ${permissions}</p>`
                        +` `+
                        `</div>`;

                    let title = '<p class="font-semibold m-0 text-lg">Resumen del rol</p>';

                    this.showSwal("#22C55E", message, "success", title);
                })
                .catch((error) => {

                    let message;

                    switch(error.response.status)
                    {
                        case 400:
                            message = '<p class="m-0 text-sm">No se ha hecho la petición de forma correcta. No se han recuperado los datos del rol, por favor vuelve a intentarlo.</p>';
                            break;

                        case 403:
                            message = '<p class="m-0 text-sm">Acceso denegado.</p>';
                            break;

                        case 404:
                            message = '<p class="m-0 text-sm">No se han recuperado los datos del rol porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 405:
                            message = '<p class="m-0 text-sm">No se han recuperado los datos del rol porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;

                        case 500:
                            message = '<p class="m-0 text-sm">No se han recuperado los datos del rol porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 504:
                            message = '<p class="m-0 text-sm">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos del rol, por favor vuelve a intentarlo.</p>';
                            break;

                        case 509:
                            message = '<p class="m-0 text-sm">Se ha superado el ancho de banda disponible. No se han recuperado los datos del rol, por favor vuelve a intentarlo.</p>';
                            break;

                        default:
                            message = '<p class="m-0 text-sm">No se han recuperado los datos del rol porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;
                    }

                    let title = '<p class="font-semibold m-0 text-center">Atención</p>';

                    this.showSwal("#FFA500", message, "error", title);
                });
            })
        })
    }

    showUser()
    {
        document.querySelectorAll('.showUser').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");

                axios({
                    body: JSON.stringify({ id }),
                    method: 'put',
                    url: `/users/show/${id}`
                })
                .then((response) => {
                    let permissions = (response.data.permissions.length != 0) ? `` : 'No tiene permisos individuales asignados';
                    let roles = (response.data.roles.length != 0) ? `` : 'No tiene roles asignados';

                    response.data.permissions.forEach(function (value, index) {
                       permissions += `<div class="px-3 text-justify text-sm">`
                        +` `+
                        `<span class="font-semibold">${ index+1 }.</span>`
                        +` `+
                        `<span>${ value.name }</span>`
                        +` `+
                        `<span class="font-semibold text-slate-500">(${ value.description })</span>`
                        +` `+
                        `</div>`;
                    });

                    response.data.roles.forEach(function (value, index) {
                        roles += `<div class="px-3 text-justify text-sm">`
                         +` `+
                         `<span class="font-semibold">${ index+1 }.</span>`
                         +` `+
                         `<span>${ value.name }</span>`
                         +` `+
                         `<span class="font-semibold text-slate-500">(${ value.description })</span>`
                         +` `+
                         `</div>`;
                     });

                    let message =
                        `<div class=text-left>`
                        +` `+
                        `<p class="m-0 text-sm"><span class="font-semibold">Nombre: </span>${response.data.name}</p>`
                        +` `+
                        `<p class="m-0 text-sm"><span class="font-semibold text-justify">Email: </span>${response.data.email}</p>`
                        +` `+
                        `<p class="m-0 text-sm"><span class="font-semibold text-justify">Teléfono: </span>${response.data.phone}</p>`
                        +` `+
                        `<p class="m-0 text-sm"><span class="font-semibold">Cargo: </span>${response.data.position}</p>`
                        +` `+
                        `<p class="m-0 text-sm"><span class="font-semibold">Roles: ${roles}</p>`
                        +` `+
                        `<p class="m-0 text-sm"><span class="font-semibold">Permisos: ${permissions}</p>`
                        +` `+
                        `</div>`;

                    let title = '<p class="font-semibold m-0 text-lg">Resumen del usuario</p>';

                    this.showSwal("#22C55E", message, "success", title);
                })
                .catch((error) => {

                    let message;

                    switch(error.response.status)
                    {
                        case 400:
                            message = '<p class="m-0 text-sm">No se ha hecho la petición de forma correcta. No se han recuperado los datos del usuario, por favor vuelve a intentarlo.</p>';
                            break;

                        case 403:
                            message = '<p class="m-0 text-sm">Acceso denegado.</p>';
                            break;

                        case 404:
                            message = '<p class="m-0 text-sm">No se han recuperado los datos del usuario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 405:
                            message = '<p class="m-0 text-sm">No se han recuperado los datos del usuario porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;

                        case 500:
                            message = '<p class="m-0 text-sm">No se han recuperado los datos del usuario porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 504:
                            message = '<p class="m-0 text-sm">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos del usuario, por favor vuelve a intentarlo.</p>';
                            break;

                        case 509:
                            message = '<p class="m-0 text-sm">Se ha superado el ancho de banda disponible. No se han recuperado los datos del usuario, por favor vuelve a intentarlo.</p>';
                            break;

                        default:
                            message = '<p class="m-0 text-sm">No se han recuperado los datos del usuario porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;
                    }

                    let title = '<p class="font-semibold m-0 text-center">Atención</p>';

                    this.showSwal("#FFA500", message, "error", title);
                });
            })
        })
    }

    showSwal(confirmButtonColor, html, icon, title)
    {
        Swal.fire({
            backdrop: 'rgba(0, 0, 0, 0.95)',
            background: '#FFFFFF',
            confirmButtonColor: confirmButtonColor,
            customClass: "md:w-1/2 w-11/12",
            html: html,
            icon: icon,
            title: title
        })
    }

    showSwalConfirm(cancelButtonText, confirmButtonColor, confirmButtonText, html, icon, title)
    {
        let modal =  Swal.fire({
            cancelButtonText: cancelButtonText,
            confirmButtonColor: confirmButtonColor,
            confirmButtonText: confirmButtonText,
            customClass: "md:w-1/2 w-11/12",
            focusConfirm: false,
            html: html,
            icon: icon,
            showCancelButton: true,
            showCloseButton: true,
            title: title
        })

        return modal;
    }

    showToasted(theme, type, message)
    {
        const toasted = new Toasted({
            duration: 5000,
            position: "bottom-left",
            theme: theme,
            type: type
        })

        toasted.show(message);
    }

    toggleProfile() {
        if (document.body.contains(document.querySelector('#btnProfile')))
        {
            let btnToogle =  document.querySelector('#btnProfile');

            btnToogle.addEventListener('click', event => {
                document.getElementById('myDropdown').classList.toggle("invisible");
            })
        }
    }

    toggleSidebar() {
        if (document.body.contains(document.querySelector('#btnSideBar')))
        {
            let btnSideBar =  document.querySelector('#btnSideBar');

            btnSideBar.addEventListener('click', event => {
                let area = document.getElementById('area');
                let containerProfile = document.getElementById('containerProfile');
                let sidebar = document.getElementById('sidebar');

                area.classList.toggle("md:w-5/6");
                area.classList.toggle("md:w-full");
                containerProfile.classList.toggle("md:w-3/6");
                sidebar.classList.toggle("md:hidden");
            })
        }
    }
}

window.onload = () => {
    let base = new Base();

    base.checkedHour();
    base.chooseAsset();
    base.chooseControl();
    base.chooseThreat();
    base.closeMenu();
    base.destroyAsset();
    base.destroyControl();
    base.destroyIncident();
    base.destroyItem();
    base.destroyKind();
    base.destroyPosition();
    base.destroyProcess();
    base.destroyRisk();
    base.destroyRole();
    base.destroyThreat();
    base.destroyUnit();
    base.destroyUser();
    base.disableForm();
    base.manageMode();
    base.recalculateRisks();
    base.replaceEnterSpaces();
    base.selectConfig();
    base.showAsset();
    base.showItem();
    base.showRole();
    base.showUser();
    base.toggleProfile();
    base.toggleSidebar();
};
